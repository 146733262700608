/* Gallery details */
if($('#phototourgallery').length > 0) {
	FWDRLUtils.onReady(function(){
		new FWDUGP({
			//main settings 
			gridType:"classicHorizontal",
			displayType:"fluidWidth",
			rightClickContextMenu:"default",
			instanceName:"myUGP",
			parentId:"phototourgallery",
			mainFolderPath:"/generated/content",
			gridSkinPath:"grid_skin_classic",
			lightboxSkinPath:"lightbox_skin_classic",
			playlistId:"phototourimageslist",
			allCategoriesLabel:"ALL GALLERIES",
			autoScale:"yes",
			showFullScreenButton:"no",
			addMouseWheelSupport:"no",
			showAllCategories:"no",
			randomizeCategories:"no",
			backgroundColor:"#000000",
			zIndex:1,
			startAtCategory:0,
			maxWidth:1600,
			maxHeight:500,
			//menu settings
			menuType:"list",
			showMenu:"no",
			multipleCategorySelection:"no",
			showMenuButtonsSpacers:"no",
			showTotalThumbnailsPerCategory:"no",
			showSearchBox:"no",
			centerNoSearchFoundLabelWithMenu:"no",
			comboboxSelectorLabel:"SELECT GALLERIES",
			menuPosition:"left",
			searchLabel:"search",
			searchNotFoundLabel:"NOTHING FOUND!",
			comboboxArrowNormalColor:"#FFFFFF",
			comboboxArrowSelectedColor:"#000000",
			menuButtonSpacerWidth:1,
			menuButtonSpacerHeight:20,
			menuButtonsSapcerLeftAndRight:0,
			menuMaxWidth:920,
			menuOffsetTop:0,
			menuOffsetBottom:0,
			horizontalSpaceBetweenMenuButtons:10,
			verticalSpaceBetweenMenuButtons:10,
			//thumbnail settings
			thumbnailLoadingType:"loadMoreWithButton",//loadMoreWithButton, loadMoreOnScroll
			hideAndShowTransitionType:"scale",
			thumbanilBoxShadow:"none",
			disableThumbnails:"no",
			inverseButtonsIcons:"no",
			thumbnailBackgroundColor:"#333333",
			thumbnailBorderNormalColor:"#FFFFFF",
			thumbnailBorderSelectedColor:"#c1c1c1",
			howManyThumbnailsToDisplayPerSet:25,
			thumbnailsHorizontalOffset:0,
			thumbnailsVerticalOffset:0,
			thumbnailMaxWidth:278,
			thumbnailMaxHeight:188,
			horizontalSpaceBetweenThumbnails:0,
			verticalSpaceBetweenThumbnails:0,
			thumbnailBorderSize:0,
			thumbnailBorderRadius:0,
			//load more button settings
			loadMoreButtonLabel:"LOAD MORE IMAGES FROM TOUR",
			loadMoreButtonOffsetTop:30,
			loadMoreButtonOffsetBottom:30,
			//preset settings
			preset:"animtext",
			textVerticalAlign:"center",
			textAnimationType:"scale",
			useIconButtons:"no",
			thumbnailOverlayColor:"#000000",
			thumbnailOverlayOpacity:.7,
			thumbnailIconWidth:30,
			thumbnailIconHeight:29,
			spaceBetweenThumbanilIcons:12,
			spaceBetweenTextAndIcons:10,
			//ligtbox settings (optional)
			facebookAppId:"",
			buttonsAlignment:"in",
			itemBoxShadow:"none",
			descriptionWindowAnimationType:"opacity",
			descriptionWindowPosition:"bottom",
			useAsModal:"no",
			slideShowAutoPlay:"no",
			addKeyboardSupport:"yes",
			showCloseButton:"yes",
			showShareButton:"no",
			showZoomButton:"no",
			showSlideShowButton:"no",
			showSlideShowAnimation:"yes",
			showNextAndPrevButtons:"yes",
			showNextAndPrevButtonsOnMobile:"yes",
			showDescriptionButton:"no",
			showDescriptionByDefault:"no",
			videoShowFullScreenButton:"no",
			videoAutoPlay:"no",
			nextVideoOrAudioAutoPlay:"no",
			videoLoop:"no",
			audioAutoPlay:"no",
			audioLoop:"no",
			backgroundOpacity:.6,
			descriptionWindowBackgroundOpacity:.95,
			buttonsHideDelay:3,
			slideShowDelay:4,
			defaultItemWidth:640,
			defaultItemHeight:480,
			itemOffsetHeight:50,
			spaceBetweenButtons:1,
			buttonsOffsetIn:2,
			buttonsOffsetOut:5,
			itemBorderSize:0,
			itemBorderRadius:0,
			itemBackgroundColor:"#333333",
			itemBorderColor:"#333333",
			lightBoxBackgroundColor:"#000000",
			descriptionWindowBackgroundColor:"#FFFFFF",
			videoPosterBackgroundColor:"#0099FF",
			videoControllerBackgroundColor:"#FFFFFF",
			audioControllerBackgroundColor:"#FFFFFF",
			timeColor:"#000000"
		});
	});
}

function validateEmail(email) {
	var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	return re.test(email);
}

$(function() {
	$('#contact_form').ajaxForm({
		beforeSubmit: function(arr, $form, options) {
			var fname = $("input[name=name]").val();
			var femail = $("input[name=email]").val();
			var fphone = $("input[name=phone]").val();
			var fmessage = $("textarea[name=message]").val();

			$("#contact_error").removeClass('hidden');

			if(fname == "") {
				$("#contact_error").text($.txt_error_name);
				return false;
			}
			if(femail == "" && fphone == "") {
				$("#contact_error").text($.txt_error_email_or_phone);
				return false;
			}
			if(fmessage == "") {
				$("#contact_error").text($.txt_error_message);
				return false;
			}
			if(femail != "" && !validateEmail(femail)) {
				$("#contact_error").text($.txt_error_email);
				return false;
			}

			$("#contact_error").addClass('hidden');
			return true;
		},
		success: function() {
			$('#contact_form').slideUp(500, function() {
				$("#thankyou").removeClass('hidden');
			});
		},
		error: function(data) {
			var msg = data.responseJSON[Object.keys(data.responseJSON)[0]][0];
			$("#contact_error").removeClass('hidden');
			$("#contact_error").text(msg);
		}
	});
})
